<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-col :md="12" align="center">
                  <el-row type="flex" justify="end" style="margin-top: 5px">
                    <el-dropdown
                      @command="(c) => (cashierStatus = c) | fetchCashiers()"
                      size="medium"
                      trigger="click"
                    >
                      <el-button type="primary" size="medium">
                        Filtrar por: {{ CashierStatus[cashierStatus] || ""
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="created">
                            <li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'created',
                              }"
                            >
                              Abertos
                            </li>
                          </el-dropdown-item>
                          <el-dropdown-item command="rejected"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'rejected',
                              }"
                            >
                              Rejeitados
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="checked"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'checked',
                              }"
                            >
                              Conferidos
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="completed"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: cashierStatus === 'completed',
                              }"
                            >
                              Finalizados
                            </li></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-row>
                </el-col>
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterCashierModal()"
                ></el-button>
                <el-button
                  type="primary"
                  class="mb-0"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Cashiers"
      style="width: 100%; z-index: 0"
    >
      <el-table-column label="açougue" prop="firm.name"></el-table-column>
      <el-table-column prop="op" label="operador">
        <template #default="c">
          {{ c?.row?.op }}
        </template>
      </el-table-column>
      <el-table-column label="data" :formatter="(r) => formatDate(r.closed_at)">
      </el-table-column>
      <el-table-column label="geral">
        <template #default="r">
          <div
            :class="{
              'is-negative': calculateTotalGlobal(r.row) < 0,
              'is-positive': calculateTotalGlobal(r.row) >= 0,
            }"
          >
            {{ currencyFormatter.format(calculateTotalGlobal(r.row)) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="finalizadora"
        :formatter="(r) => currencyFormatter.format(Number(r.total_sells) || 0)"
      ></el-table-column>
      <el-table-column
        label="sangria"
        :formatter="(r) => currencyFormatter.format(Number(r.withdrawal) || 0)"
      ></el-table-column>
      <el-table-column
        label="total em caixa"
        prop="total_cashier"
        :formatter="
          (r) => currencyFormatter.format(Number(r.total_cashier) || 0)
        "
      ></el-table-column>
      <el-table-column
        label="conferência"
        prop="total_cashier"
        :formatter="(r) => currencyFormatter.format(Number(r.check_total) || 0)"
      ></el-table-column>
      <el-table-column label="resultado" prop="total_cashier">
        <template #default="r">
          <div
            :class="{
              'is-negative': calculateResult(r.row) < 0,
              'is-positive': calculateResult(r.row) >= 0,
            }"
          >
            {{ currencyFormatter.format(calculateResult(r.row)) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row v-show="Cashiers?.length">
      <el-col class="col-1-1">
        <p class="summary summary-black">Total:</p>
      </el-col>
      <el-col class="col-1-1"></el-col>
      <el-col class="col-1-1"></el-col>
      <el-col class="col-1-1"
        ><p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => calculateTotalGlobal(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col class="col-1-1">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.total_sells)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col class="col-1-1">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.withdrawal)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col class="col-1-1">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.total_cashier)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col class="col-1-1">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => Number(r.check_total)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
      <el-col class="col-1-1">
        <p
          :class="{
            summary: true,
            'is-negative':
              (Cashiers?.map((r) => calculateResult(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0) < 0,
            'is-positive':
              (Cashiers?.map((r) => calculateResult(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0) >= 0,
          }"
        >
          {{
            currencyFormatter.format(
              Cashiers?.map((r) => calculateResult(r)).reduce(
                (t, e) => (t += e),
                0
              ) || 0
            )
          }}
        </p>
      </el-col>
    </el-row>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="cashiers?.lastPage"
    >
    </el-pagination>
    <cashier-close-info-modal
      :showModal="showCashierCloseInfoModal"
      :cashier="cashier"
      @close-modal="showCashierCloseInfoModal = false"
      @should-update="fetchCashiers"
    ></cashier-close-info-modal>
    <cashier-result-info-modal
      :showModal="showCashierResultInfoModal"
      :cashier="cashier"
      @close-modal="showCashierResultInfoModal = false"
      @should-update="fetchCashiers"
    ></cashier-result-info-modal>
    <filter-cashier-modal
      :showModal="showFilterCashierModal"
      @close-modal="showFilterCashierModal = false"
      @update-filters="updateFilters"
    ></filter-cashier-modal>
  </el-card>
</template>

<script>
import { ElNotification /*ElMessageBox*/ } from "element-plus";
import CashierCloseInfoModal from "./modals/CashierCloseInfoModal.vue";
import FilterCashierModal from "./modals/FilterCashierModal.vue";
import CashierResultInfoModal from "./modals/CashierResultInfoModal.vue";

export default {
  name: "CashierCloseReport",
  components: {
    CashierCloseInfoModal,
    CashierResultInfoModal,
    FilterCashierModal,
  },
  data: () => ({
    cashierStatus: "created",
    showCashierCloseInfoModal: false,
    showCashierResultInfoModal: false,
    showFilterCashierModal: false,
    cashiers: null,
    currentPage: 1,
    cashier: null,
    filterOptions: {},
    isLoadingDownloadButton: false,
    isLoading: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchCashiers();
  },
  computed: {
    Cashiers() {
      return this.cashiers?.data || [];
    },
    CashierStatus() {
      return {
        created: "Aberto",
        rejected: "Rejeitado",
        checked: "Conferido",
        completed: "Finalizado",
      };
    },
  },
  watch: {
    currentPage() {
      this.fetchCashiers();
    },
  },
  methods: {
    openCashierResultInfoModal(cashier) {
      this.cashier = cashier;
      this.showCashierResultInfoModal = true;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}cashiers/report`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
        status: this.cashierStatus,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Fechamento de Caixa - ${
            this.filterOptions.firm_id || ""
          }.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(async (e) => {
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          });
        })
        .finally(() => this.changeDownloadButtonStatus());
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openFilterCashierModal() {
      this.showFilterCashierModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchCashiers();
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    calculateResult(r) {
      return Number(Number(r.total_cashier) - Number(r.check_total)) || 0;
    },
    calculateTotalGlobal(r) {
      return (
        Number(r.total_sells) +
          Number(r.discount) +
          Number(r.canceled) +
          Number(r.start) || 0
      );
    },
    fetchCashiers() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}cashiers`);
      url.search = new URLSearchParams({
        status: this.cashierStatus,
        pagination: this.currentPage,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.cashiers = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
.col-1-1 {
  max-width: 11.1% !important;
}
tr.unavailable {
  background-color: #fffde7;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #909399;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: center;
}
.summary-black {
  color: #333 !important;
}
</style>